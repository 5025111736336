<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body px-2">
            <DualListBox
              :source="locations"
              :destination="selectedLocations"
              label="title"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            >
              <template v-slot:footer>
                <div class="bg-gray-100 text-center p-2 border-t rounded-b-lg">
                  <button
                    @click="setStartEndDate()"
                    class="btn btn-light-primary btn-sm btn-block"
                  >
                    Start/End
                  </button>
                </div>
              </template>
            </DatePicker>
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold mt-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button
          v-if="!isGenerated"
          class="btn btn-primary btn-block font-weight-bold"
          @click="generate"
        >
          Prepare Data
        </button>
        <button
          v-else
          class="btn btn-primary btn-block font-weight-bold"
          @click="resetAll"
        >
          Reset All
        </button>
        <div v-if="isGenerated" class="card card-custom">
          <div class="card-body">
            <b>Selected locations:</b> {{ selectedLocations.length }}<br />
            <b>Date from:</b> {{ range.start | formatDateTime }} <br />
            <b>Date to:</b> {{ range.end | formatDateTime }}
          </div>
        </div>
        <button
          v-if="isGenerated"
          class="btn btn-primary btn-block font-weight-bold"
          @click="generateExport"
        >
          Generate
        </button>
        <div class="row">
          <div class="col-12 text-center my-5">
            <b-spinner
              v-if="showSpinner"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDataGenerate" class="row">
      <div class="col-md-12">
        <DevicesTrafficNew
          v-if="isGenerated && isDataGenerate"
          :devices="payloadDevices"
          :no-traffic-devices="payloadNoTrafficDevices"
          :download-excel-url="downloadExcelUrl"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportByDaysInRange
      "
      class="row"
    >
      <div class="col-md-12">
        <DaysTrafficNew
          v-if="isGenerated"
          :type="'device'"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportWeek
      "
      class="row"
    >
      <div class="col-md-12">
        <WeekTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportMonth
      "
      class="row"
    >
      <div class="col-md-12">
        <MonthTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportYear
      "
      class="row"
    >
      <div class="col-md-12">
        <YearTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.AverageByDaysInWeek
      "
      class="row"
    >
      <div class="col-md-12">
        <WeekDaysTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.AverageByHoursOfTheDay
      "
      class="row"
    >
      <div class="col-md-12">
        <HoursTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import format from 'date-fns/format'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { dateFilter } from 'vue-date-fns'
import ApiService from '@/core/services/api.service'
import addDays from 'date-fns/addDays'
import axios from 'axios'
import DevicesTrafficNew from '@/view/pages/finance-report/_components/DevicesTrafficNew.vue'
import DaysTrafficNew from '@/view/pages/finance-report/_components/DaysTrafficNew.vue'
import WeekTrafficNew from '@/view/pages/finance-report/_components/WeekTrafficNew.vue'
import MonthTrafficNew from '@/view/pages/finance-report/_components/MonthTrafficNew.vue'
import YearTrafficNew from '@/view/pages/finance-report/_components/YearTrafficNew.vue'
import WeekDaysTrafficNew from '@/view/pages/finance-report/_components/WeekDaysTrafficNew.vue'
import HoursTrafficNew from '@/view/pages/finance-report/_components/HoursTrafficNew.vue'

export default {
  name: 'LocationNewFinanceReport',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    DualListBox,
    DevicesTrafficNew,
    DaysTrafficNew,
    WeekTrafficNew,
    MonthTrafficNew,
    YearTrafficNew,
    WeekDaysTrafficNew,
    HoursTrafficNew,
  },
  data() {
    return {
      locations: [],
      selectedLocations: [],
      url: null,
      totalItems: null,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],

      showAlert: false,
      alertMsg: null,

      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
      downloadExcelUrl: null,

      payloadDevices: [],
      payloadNoTrafficDevices: [],
      transactions: [],
      isDataGenerate: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Location reports', route: '/finance-report/location' },
    ])

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get('locations', `?company=${this.currentUserCompany}`).then(
        ({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.locations = [...data['hydra:member']]

          this.isLoaded = true
        }
      )
    }
  },
  methods: {
    generate() {
      if (
        this.selectedLocations.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select location/s & pick range'
        this.showAlert = true

        return
      }

      this.showSpinner = true

      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let locationsIds = ''

      this.selectedLocations.forEach((location) => {
        let l = location['@id'].replace('/api/v1/locations/', '')
        locationsIds += l + ','
      })

      this.url = `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/location-finance-report/${locationsIds}/${from}/${end}/${this.currentUserCompanyOib}`

      console.log('url', this.url)

      this.showSpinner = false
      this.isGenerated = true
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.totalItems = null
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
      this.locations = this.selectedLocations
      this.selectedLocations = []
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    onChangeList: function ({ source, destination }) {
      this.locations = source
      this.selectedLocations = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
    generateExport() {
      this.showSpinner = true
      this.isDataGenerate = false
      axios
        .get(this.url)
        .then(({ data }) => {
          if (data.status === 'ok') {
            this.downloadExcelUrl = data.fileUrl
            this.payloadDevices = data.devices
            this.payloadNoTrafficDevices = data.noTrafficDevices
            this.transactions = data.transactions

            this.isDataGenerate = true

            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Success',
            })
            this.showSpinner = false
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Server error',
          })
          this.showSpinner = false
          this.isDataGenerate = false
        })
    },
  },
}
</script>
